<template>
  <b-overlay :show="isLoading">
    <b-row v-if="blogDetails">
      <b-col cols="12">
        <b-card>
          <h1>ویرایش نوشته {{ blogTitle }}</h1>
          <hr class="mb-3" />
          <b-row>
            <b-col cols="12">
              <b-form @submit.prevent>
                <b-form-group label="عنوان نوشته" label-for="title">
                  <b-form-input
                    :state="titleState"
                    id="title"
                    v-model="blogDetails.title"
                    trim
                    placeholder="عنوان نوشته را وارد کنید"
                  />
                  <b-form-invalid-feedback>
                    <div
                      class="w-100 d-flex align-items-center justify-content-between"
                    >
                      <p>طول عنوان نباید بیشتر از 200 کاراکتر باشد.</p>
                      <template
                        v-if="
                          blogDetails &&
                          blogDetails.title &&
                          blogDetails.title.length > 0
                        "
                      >
                        {{ blogDetails.title.length }}/200
                      </template>
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="4" class="my-1">
              <b-form @submit.prevent>
                <b-form-group label="وضعیت انتشار نوشته" label-for="blogStatus">
                  <b-form-select
                    id="blogStatus"
                    v-model="blogDetails.status"
                    :options="blogStatusEnums"
                  ></b-form-select>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="4" class="my-1">
              <blog-category-picker
                :defaultCategory="blogDetails.blogCategoryId"
                @getBlogCategory="setBlogCategory"
              ></blog-category-picker>
            </b-col>
            <b-col cols="2">
              <b-form-group
                label-for="allowComments"
                label="درج نظر برای نوشته"
                class="mt-2"
              >
                <div class="d-flex align-items-center">
                  <span style="margin-left: 8px"> خیر </span>
                  <b-form-checkbox
                    id="allowComments"
                    switch
                    v-model="blogDetails.allowComments"
                  ></b-form-checkbox>
                  بله
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                label-for="hasComments"
                label="در آخرین اخبار باشد"
                class="mt-2"
              >
                <div class="d-flex align-items-center">
                  <span style="margin-left: 8px"> خیر </span>
                  <b-form-checkbox
                    switch
                    v-model="blogDetails.isNews"
                  ></b-form-checkbox>
                  بله
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form @submit.prevent>
                <b-form-group label="خلاصه نوشته" label-for="summary">
                  <b-form-textarea
                    placeholder="کارگزاری بورس ستاره جنوب ارایه دهنده انواع..."
                    id="summary"
                    v-model="blogDetails.summary"
                    :state="summaryState"
                  ></b-form-textarea>
                  <b-form-invalid-feedback>
                    <div
                      class="w-100 d-flex align-items-center justify-content-between"
                    >
                      <p>طول خلاصه نباید بیشتر از 400 کاراکتر باشد.</p>
                      <template
                        v-if="
                          blogDetails &&
                          blogDetails.summary &&
                          blogDetails.summary.length > 0
                        "
                      >
                        {{ blogDetails.summary.length }}/400
                      </template>
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12">
              <small>محتوای نوشته</small>
              <quillEditor
                ref="quillEditor"
                class="mt-1"
                v-model="blogDetails.description"
                :options="editorOption"
              >
                <div id="toolbar" slot="toolbar" class="ql-toolbar ql-snow">
                  <quill-toolbar></quill-toolbar>
                </div>
              </quillEditor>
            </b-col>
            <b-col cols="6" class="my-1">
              <b-form @submit.prevent>
                <b-form-group label="کاور نوشته" label-for="imageFile">
                  <b-form-file
                    id="imageFile"
                    v-model="blogImage"
                    accept="image/*"
                    @input="convertImageFileToBase64"
                  ></b-form-file>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="6" class="my-1">
              <div v-if="blogImage">
                <p>پیشنمایش تصویر نوشته</p>
                <div class="position-relative">
                  <img
                    style="width: 100%; max-height: 20rem; object-fit: contain"
                    :src="imagePreview"
                    alt=""
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="25"
                    class="text-danger position-absolute"
                    style="left: 1rem; top: 1rem; cursor: pointer"
                    @click="deletePreviewImage"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  type="button"
                  variant="success"
                  @click="updateThisBlog"
                >
                  ویرایش نوشته
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <library-image-picker @insertImage="insertImage"></library-image-picker>
  </b-overlay>
</template>
<script>
import BlogCategoryPicker from "@/views/components/Blog/BlogCategoryPicker.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { GetASingleBlog, UpdateABlog } from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import QuillToolbar from "@/views/components/utils/QuillToolbar.vue";
import {
  BFormInvalidFeedback,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BButton,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import LibraryImagePicker from "@/views/components/utils/LibraryImagePicker.vue";
import Helper from "@/libs/Helper";
export default {
  title: "ویرایش نوشته",
  mounted() {
    this.getASingleBlog();
  },
  data() {
    return {
      isLoading: false,
      blogDetails: null,
      blogImage: null,
      blogTitle: null,
      blogStatusEnums: [
        {
          text: "انتشار",
          value: 1,
        },
        {
          text: "آرشیو",
          value: 2,
        },
      ],
      editorOption: {
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: this.imageMethod,
            },
          },
        },
        // Some Quill options...
        theme: "snow",
      },
    };
  },
  methods: {
    imageMethod() {
      this.$bvModal.show("library-image-picker-modal");
    },
    insertImage(imageUrl) {
      this.$refs.quillEditor.quill.insertEmbed(
        this.$refs.quillEditor.quill.getSelection().index,
        "image",
        imageUrl
      );
    },
    async updateThisBlog() {
      try {
        this.isLoading = true;
        let _this = this;
        if (!this.blogDetails.seourl.startsWith("/")) {
          this.blogDetails.seourl = `/${this.blogDetails.seourl}`;
        }
        let updateABlog = new UpdateABlog(_this);
        updateABlog.setRequestParamDataObj(_this.blogDetails);
        await updateABlog.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: "نوشته با موفقیت ویرایش شد",
                icon: "EditIcon",
                variant: "success",
              },
            });
            _this.getASingleBlog();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getASingleBlog() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleBlog = new GetASingleBlog(_this);
        getASingleBlog.setRequestParam({
          id: _this.$route.params.id,
        });
        await getASingleBlog.fetch((response) => {
          if (response.isSuccess) {
            _this.blogDetails = response.data;
            _this.blogTitle = JSON.parse(JSON.stringify(response.data.title));
            _this.blogImage = JSON.parse(
              JSON.stringify(response.data.imageFile)
            );
            _this.blogDetails.imageFile = null;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    deletePreviewImage() {
      this.blogImage = null;
      this.blogDetails.imageFile = "delete";
    },
    convertImageFileToBase64() {
      const reader = new FileReader();
      reader.readAsDataURL(this.blogImage);
      reader.onload = () => {
        this.blogDetails.imageFile = reader.result.split(",")[1];
      };
    },
    setBlogCategory(categoryId) {
      this.blogDetails.blogCategoryId = categoryId;
    },
  },
  computed: {
    imagePreview() {
      if (
        this.blogImage &&
        this.blogImage != "delete" &&
        typeof this.blogImage != "string"
      ) {
        return URL.createObjectURL(this.blogImage);
      } else {
        return `${Helper.baseUrl}/Media/Gallery/Blog/${this.blogImage}`;
      }
    },
    titleState() {
      if (this.blogDetails && this.blogDetails.title) {
        if (this.blogDetails.title.length > 200) {
          return false;
        } else if (this.blogDetails.title.length < 200) {
          return true;
        }
      } else {
        return null;
      }
    },
    summaryState() {
      if (this.blogDetails && this.blogDetails.summary) {
        if (this.blogDetails.summary.length > 400) {
          return false;
        } else if (this.blogDetails.summary.length < 400) {
          return true;
        }
      } else {
        return null;
      }
    },
    isCreateButtonDisabled() {
      if (this.summaryState && this.titleState) {
        return false;
      }
      return true;
    },
  },
  components: {
    BlogCategoryPicker,
    quillEditor,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BButton,
    BPagination,
    BOverlay,
    BFormInvalidFeedback,
    LibraryImagePicker,
    QuillToolbar,
  },
};
</script>
